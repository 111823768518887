
pagination .pagination-first {
  border: 1px solid red;
}



$color-pagination-button-disabled: #b3b3b3;
$color-border-pagination-button-disabled: #dedede;
$background-hover-pagination-button: white;
$color-hover-pagination-button: $uxColor;

pagination .pagination-page.page-item {
  border: 1px solid $uxColor;
  background-color: white;
  color: $uxColor;

  .page-link {
    background-color: inherit;
    border: 1px solid $uxColor;
    color: inherit;
  }

  &.active {
    background-color: $uxColor;
    color: white !important;

    &:hover {
      background-color: white;
      color: $color-hover-pagination-button !important;

    }
  }

  &:hover {
    color: $color-hover-pagination-button;
    background-color: $background-hover-pagination-button;

  }
}


pagination .pagination-next.page-item, .pagination-last.page-item, .pagination-first.page-item, .pagination-prev.page-item {
  border: 1px solid $uxColor;
  background-color: $uxColor;
  color: white;

  .page-link {
    background-color: inherit;
    border: 1px solid $uxColor;
    color: inherit;
  }

  &:hover {
    color: $color-hover-pagination-button;
    background-color: $background-hover-pagination-button;
  }

  &.disabled {
    background-color: white;
    color: $color-pagination-button-disabled;
    border: 1px solid $color-border-pagination-button-disabled;

    .page-link {
      border: 1px solid $color-border-pagination-button-disabled;
      color: $color-pagination-button-disabled;
    }
  }
}
