
// Flex

.flex {
  display: flex;
  align-items: center
}

.inline-flex {
  display: inline-flex
}

.f-column {
  flex-direction: column
}

.f-row {
  flex-direction: row
}

.j-center {
  justify-content: center
}

.spb {
  justify-content: space-between
}

.spa {
  justify-content: space-around
}

.spe {
  justify-content: space-evenly;
}

.j-start{
  justify-content: flex-start
}

.j-end {
  justify-content: flex-end
}

.start {
  align-self: flex-start
}

.end {
  align-self: flex-end
}

.wrap {
  flex-wrap: wrap
}

.alignItemCenter {
  align-items: center
}

.alignItemStart{
  align-items: start
}

.alignItemFlexStart {
  align-items: flex-start;
}

.alignItemEnd{
  align-items: flex-end
}

.alignItemBase{
  align-items: baseline;
}

.alignSelfStart{
  align-self: start
}

.alignSelfEnd{
  align-self: end
}

.alignSelfCenter{
  align-self: center
}
