

html,
body{
  font-family: 'Poppins-Regular',Arial, Helvetica, sans-serif;
  background: $light-gray;
  height: 100%;
  margin: 0;
}

body {
  display: block;
  background-color: $backgroundColor;
}

a {
  text-decoration: none;

  &:visited {

  }

  &:hover {
    color: white;
    text-decoration: none;
  }
}


header a {
  text-decoration: underline;
}


.mainColor {
  background: $mainColor;
}

.uxColor {
  background: $uxColor;
}

.gray {
  color: gray;
}

.white {
  color: white !important;
}

.text {
  color: $text-color;
  text-align: left;
}

.text-align-center {
  color: $text-color;
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.menuText {
  color: inherit;
  text-align: left;
  opacity: 0;
}

.textOpen {
  display: block;
  opacity: 1;
  transition: opacity 1s;
}
