.modal-header {
  background: $header-color
}

.modal-header > .title-info {
  font-size: 20px
}

.modal-header > .title-details {
  font-size: 1.3em
}


.modal-content{
  border-radius: 0.41rem;
}

.modal-color-body{
  background-color: $backgroundColor;
}

.modal-body {
  padding: 2rem;
}


// CUSTOM MODAL

.cmodalContainer {
  width: 100vw;
  height: 100vh;
  background: rgba(105, 105, 105, 0.5);
}

.cmodal {
  width: 850px;
  height: 600px;
  z-index: 1050;
  margin: auto;
  background: white;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
}

.modal-body {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.m-text, transaction-text {
  color: $text-color;
  line-height: 35px
}

.transaction-text{
  font-size: 16px;
}

.value-text {
  color: gray;
}

.alert-text{
  text-align: center;
  color: $darkColor;
}

.m-close {
  cursor: pointer;
  background: transparent;
  border:0;
  opacity: 1;
  position: absolute;
  right: 15px;
}

.m-close > span {
  font-weight: 600
}
