

.weight{
  font-weight: bold;
}

.green {
  background: #1ea85a;

  &:hover {
    background: #20c768
  }
}

.red {
  background: #ff5252;

  &:hover {
    background: #fd7a7a;
  }
}

.dot-container {
  position: relative;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.drop {
  position: relative
}

.abs-drop {
  position: absolute
}

//>>>>>>>>>>>>>>>>>>>>>>>> MARGINS & PADDINGS
@mixin margin-classes {
  @for $i from 0 through 5 {
    $margin: 15px * $i;
    .mgr#{$i} {margin-right: $margin;}
    .mgl#{$i} {margin-left: $margin;}
    .mgb#{$i} {margin-bottom: $margin;}
    .mgt#{$i} {margin-top: $margin;}

  }
  .mg0 {margin: 0;}
  .mg {margin: 15px;}
}

@mixin padding-classes {
  @for $i from 0 through 1 {
    $padding: 15px * $i;
    .pdr#{$i} {padding-right: $padding;}
    .pdl#{$i} {padding-left: $padding;}
    .pdb#{$i} {padding-bottom: $padding;}
    .pdt#{$i} {padding-top: $padding;}

  }
  .pd0 {padding: 0;}
  .pd1 {padding: 15px;}
}

@include margin-classes;
@include padding-classes;



.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
