@import '../abstract/variables';

// Metrics vars
$border-raidus: 4px;

.loading-color {
  color: $uxColor;
}

// CLASSES
.metrics-wrap {
  background-color: white;
  border-radius: $border-raidus;
  overflow:visible;
  -webkit-box-shadow: 0px 0px 36px 2px rgba(0,0,0,0.43);
  -moz-box-shadow: 0px 0px 36px 2px rgba(0,0,0,0.43);
  box-shadow: 0px 0px 36px 2px rgba(0,0,0,0.43);

  & > .metrics-title {
    background-color: rgba(37, 37, 37, 0.8);
    user-select: none;
    color: white;
    padding: 10px 25px;
    border-top-left-radius: $border-raidus;
    border-top-right-radius: $border-raidus;

    & > h4 {
      margin:0;
      font-size: 18px
    }
  }

  & > .metrics-container {
    width: 100%;
    padding: 15px;
    margin: 0 auto;
    position: relative;

    & > .metrics-content {
      flex: 1 1 100%;
      position: relative;
      height: 100%;
    }

    & > .loader {
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      width: 100%;
      position: absolute;
      top: 0;
      height: 100%;
      background: white;
      transition: all .4s;

      & > .spinner-border {
        width: 8rem;
        height: 8rem;
      }
    }
  }
}

.counter-wrapper {

  & > .counter-number {

    & > h4 {
      margin:0;
      font-size: 48px;
      font-weight: bold;
    }
  }
  & > .counter-text {
    font-size: 20px;
  }
}
