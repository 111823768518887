//>>>>>>>>>>>>>>>>>>>>>>> Sales rep

//>>> Modal detail
.sales-detail-label {
  color: black;
}


//>>>>>>>>>>>>>>>>>>>>>>> Transactions

.table-disabled {
  background: white;
  height: 45px !important;
  transition: background 0.2s;
  cursor: default;
  padding: 0 1em;
  box-sizing: border-box;
  text-align: center;
  color: #dcdcdc !important;
}

.th-disabled {
  color: #dcdcdc !important;
}

//>>>>>>>>>>>>>>>>>>>>>>> Guide
.guide-padding {
  padding-left: 30px;
}

.guide-title {
  font-size: 30px;
  font-weight: 600;
}

.guide-subtitle {
  font-size: 25px;
}

.guide-paraph {
  font-size: 20px;
}

.guide-li-pointer {
  cursor: pointer;
}

.transaction-header-label {
  color: lightgrey;
}

.transaction-header-value {
  font-weight: bold;
  color: #252525;
}

//>>>>>>>>>>>>>>>>>>>>>>> Inventory legend

.map-legend-container {
  background-color: #252525;
  height: 50px;
}

.section-data {
  text-align: center;
  padding: 13px 0;
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}

.legend {
  text-align: left;
  padding-left: 22px;
  width: 455px;
  font-family: 'Poppins-Regular',Arial,sans-serif;
  letter-spacing: 0.2px;
  text-align: left;
  color: #ffffff;
  font-size: 14px;
}

.legend-text{
  color: white;
  margin-left: 14px !important;
}

.legend-circle{
  position: absolute;
  top: 6px;
  left: 8px;
}

.fa-circle{
  font-size: 9pt;
}

.blockmap-legend-circle-available{
  color: #1D8EF9 !important;
}

.blockmap-legend-circle-unavailable{
  color: #c7c7c7 !important;
}

.blockmap-legend-circle-selected{
  color: #eaeaea !important;
}

.seatmap-legend-circle-available{
  color: #1D8EF9 !important;
}

.seatmap-legend-circle-selected{
  // color: rgb(255, 251, 0) !important;
  color: #eaeaea !important;

}

.seatmap-legend-circle-locked{
  color: red !important;
}

.seatmap-legend-circle-reserved {
  color: orangered !important;
}

.seatmap-legend-circle-owned{
  color: orange !important;
}

.seatmap-legend-circle-no-price-type{
  color: darkgray !important;
}

.seatmap-legend-circle-hold{
  color: yellow !important;
}


//>>>>>>>>>>>>>>>>>>>>>>> ELEMENTS
.title {
  font-weight: 400;
  font-size: 1.5em;
  user-select: none;
}

.sub-title {
  font-weight: 400;
  font-size: 1.2em;
  user-select: none;
}

.giant-title {
  font-weight: 400;
  font-size: 3.5em;
  user-select: none;
}

.logintTitle{
  font-family: $font-bold, sans-serif;
  color: gray;
}

.custom-container {
  /*width: 90%;*/
  height: auto;
  //min-height: calc(100vh - 75px);
  margin: auto;
  //overflow: auto;
  overflow: visible;
  width: 100%;
  box-sizing: border-box;
  padding: 2.5% 2.5% 0;
  min-width: 700px + $sidenav-width;
}

.dropDown {
  display: none;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  position: absolute;
  top: 50px;
  background-color: #f1f1f1;
  min-width: 150px;
  box-shadow: 0 8px 20px 0 rgba(0,0,0,.5);
  z-index: 1;
  border-radius: 4px;

  a {
    &:hover {
      background: $uxColor;
    }

    &:first-of-type {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;

      &:hover {
        border-top-right-radius: 4px;
        border-top-left-radius: 4px;
      }
    }

    &:last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;

      &:hover {
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;

      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgba(21,66,133,1) transparent;
  }
}

.menu-dropDown {
  display: none;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  position: absolute;
  left: 100%;
  min-width: 250px;
  box-shadow: 5px 0px 7px -4px rgba(0, 0, 0, 0.5);
  z-index: 2;
  overflow: hidden;
  text-decoration: none;
  color: white;
  top: 0%;
  background: -moz-linear-gradient(top, #99141F 0%, #D81C2B 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #99141F 0%,#D81C2B 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #99141F 0%,#D81C2B 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

  a {
    &:hover {
      background: $hover-side-nav-background;
      color: $hover-side-nav-text !important;
    }
  }

}

.dropDown * {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  text-decoration: none;
  color: white;
}

.drop {
  &:hover {
    .fullButton {
      background: $mainColor;
      color: white;
    }

    .dropDown {
      display: flex;
    }

    .menu-dropDown {
      display: flex;
    }

  }
}

.box-login {
  display:flex;
  padding: 15px;
  justify-content: center;
  flex-direction: column;
}

.box-filter {
  background: white;
  padding: 15px;
}

//>>>>>>>>>>>>>>>>>>>>>>> Transactions VIEW

.transaction-col {
  height: 550px !important;
  overflow: auto;
}

//>>>>>>>>>>>>>>>>>>>>>>> Acordeon
.accordion-column {
  max-height: 420px;
  overflow-y: auto;
}

.accordion-title {
  padding: 15px;
  /*  border: 1px solid black; */
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background: white;
  border: 1px white solid;

  &:hover {
    border: 1px $uxColor solid;
  }
}

.zones {
  overflow: hidden;
  padding: 15px;
  background-color: white;
  max-height: 350px;
  //overflow-y: auto;
  transition: max-height 0.2s ease-out
}

.border-unfold {
  border:1px $uxColor solid;
}

.zones > form > label {
  font-size: 0.99em;
}

//>>>>>>>>>>>>>>>>>>>>>>> SideNav

#burguercont {
  position: absolute;
  left: 35px;
  top: 22px;
  width: 100px;
  height: auto;
  transition: all 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  cursor: pointer;
  z-index: 999999;
}

.b1, .b3 {
  position: relative;
  left: 0;
  width: 26px;
  height: 2px;
  background: white;
  margin: 5px 0 5px;
  border-radius: 3px;
  transition: all 0.9s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  box-sizing: border-box;

}

.b2 {
  position: relative;
  left: 0;
  width: 26px;
  height: 2px;
  background: white;
  margin: 8px 0 8px;
  border-radius: 3px;
  transition: all 0.8s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition-delay: 0.3s;
  box-sizing: border-box;

}

.burg .b1 {
  position: relative;
  left: 10px;
  transform: rotate(45deg) translate(-1px, 14px);
}

.burg .b2 {
  position: relative;
  left: 10px;
  height: 1px;
  width: 24px;
  transform: rotate(225deg) translate(6px, -7px);
  /*			opacity: 0;
              translate(-68px,0px);
  */
}

.burg .b3 {
  position: relative;
  left: 10px;
  transform: rotate(-45deg) translate(0px, -15px);
}


.sideMenu {
  width: $sidenav-width;
  position: relative;
  transition: 0.3s;
  height: 100vh;
  text-decoration: none;
  color: white;
  z-index: 999;
  transition: all 0.7s;
  background: linear-gradient(to bottom, #99141F 0%, #D81C2B 100%);

  span {
    font-size: 1.5em;
  }

  .full {
    height: 65px;
  }

  .hover {
    &:hover {
      background: $hover-side-nav-background;
      color: $hover-side-nav-text !important;
      cursor: pointer;
    }
  }
}

.open {
  width: 260px;
}

.active {
  background: $active-side-nav-background;
  color: $active-side-nav-text !important;
}

.tab-content > .active {
  color: black !important;
}

.nav-tabs .nav-link {
  color: $uxColor !important;
}

.nav-link.active {
  color: black !important;
}

.menu-dropdown-line {
  width: 80%;
  background-color: white;
  margin-top: unset;
  margin-bottom: unset;
}

//>>>>>>>>>>>>>>>>>>>>>>> Breadcrumbs


.t-nav-active {
  cursor: pointer;
  font-size: 11pt;
  color: $uxColor;
}

.t-nav-inactive {
  cursor: pointer;
  font-size: 11pt;
  color: #999 !important;
}

.breadcrumb {
  background-color: inherit;
  padding: 0;
}

.breadcrumb a:last-child {
  cursor: pointer;
  font-size: 11pt;
  color: $uxColor !important;
}

.t-nav-sep {
  font-size: 9pt;
  padding: 7px;
  color: #999
}

//>>>>>>>>>>>>>>>>>>>>>>> HEADER

.headerColor {
  background: $header-color;
}


.logo-image {
  max-height: 100%;
  margin-right: 20px;
  width: 150px;
}

.text-header {

}

.main-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

//>>>>>>>>>>>>>>>>>>>>>>> FOOTER


.footer:after {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1rem;
  background: linear-gradient(90deg, $mainColor 10%, $uxColor );
}


//>>>>>>>>>>>>>>>>>>>>>>> POSITION

.abs {
  position: absolute;
}

.rel {
  position: relative;
}

.abs-special {
  position: absolute;
  top: 65px;
  left: 100px;
}

.pos {
  left: -44.5%;
  margin-left: 35px;
  transition: all 0.4s;
}

.z1 {
  z-index: 9999;
}

.margin-auto {
  margin: 0 auto;
}

//>>>>>>>>>>>>>>>>>>>>>>> SIZES

.full {
  width: 100%;
  height: auto;
}

.content {
  width: 100%;
  height: 100vh;
}

.half {
  width: 50%;
  height: auto;
}

.quart {
  width: 25%;
  height: auto;
}

.w5 {
  width: 5%;
}

.w10 {
  width: 10%;
}

.w15 {
  width: 15%;
}

.w20 {
  width: 20%;
}

.w25 {
  width: 25%;
}

.w30 {
  width: 30%;
}

.w40 {
  width: 40%;
}

.w60 {
  width: 60%;
}

.w70 {
  width: 70%;
}

.w80 {
  width: 80%;
}

.w90 {
  width: 90%;
}

.w95 {
  width: 95%;
}

