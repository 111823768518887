
.buttonActive {
  background: $button-active;

  &:hover {
    background: $button-active-hover;
  }
}

.inactive {
  background: $button-inactive;

  &:hover {
    background: $button-inactive-hover;
  }
}

.trash-btn, .edit-btn {

  font-size: 20px;

  &:hover {

    transform: scale(1,1);
  }
}

.trash-btn {
  color: #d81c2b;
}

.delete-btn{
  background-color: $uxColor !important;
  &:hover {
    background-color: white !important;
  }
}

.edit-btn {
  color: $uxColor;
}




.button, .filterButton, .button-table-price {
  border-radius: 4px;
  width: auto;
  height: 40px;
  padding: 0 20px;
  color: white !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s;
  font-size: 1em;
  border: 1px solid $uxColor;

  &:hover {
    background: white;
    color: $uxColor !important;
    border: 1px solid $uxColor;
  }

}

.button-takeover {
  background-color: #008C21;
  margin: 0 0 0 auto;
  border: 1px solid #008C21;

  &:hover {
    background: white !important;
    color: #008C21 !important;
    border: 1px solid #008C21;
  }
}


.button-table-price {
  height: 25px;
  padding: 0 10px;
}

.fullButton:disabled{
  background: #C7C7C7;
  color: #C7C7C7;
  &:hover {
    background: #C7C7C7;
    color: #C7C7C7;
    cursor: not-allowed;
  }
}

.filterButton {
  &:hover {
    background: white;
  }
}

.ti-search {
  font-weight: bold !important;
}

.ti-trash {
  font-weight: bold !important;
}

.fullButton, .loginButton {
  width: 100%;
  height: 45px;
  padding: 0 1em;
  background: rgba(0, 0, 0, 0);
  color: black;
  border-style: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s;
}

.loginButton {
  border-radius: 4px;
  font-family: $font-medium;
  background: $uxColor;
}

.fullButton {
  &:hover {
    background: $uxColor;
    color: white;
  }
}

.buttonIcon {
  margin-right: 10px;
  font-size: 14px;
  margin-bottom: 1px;
  font-weight: 500
}

.logOut {
  color: white;

  &:hover {
    text-decoration: none;
    color: $uxColor;
    background: $button-hover;
  }
}
