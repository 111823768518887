/* ICONS */


.logout {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' enable-background='new 0 0 24 24'%3E%3Cpath d='M12,20c-4.411,0-8-3.589-8-8c0-2.609,1.281-5.062,3.428-6.561 C7.881,5.125,8.504,5.235,8.82,5.688C9.136,6.14,9.025,6.764,8.572,7.08C6.962,8.204,6,10.044,6,12c0,3.309,2.691,6,6,6s6-2.691,6-6 c0-1.956-0.962-3.796-2.572-4.92c-0.453-0.316-0.563-0.94-0.248-1.393c0.316-0.452,0.939-0.563,1.393-0.248 C18.719,6.938,20,9.391,20,12C20,16.411,16.411,20,12,20z M13,10V5c0-0.553-0.447-1-1-1s-1,0.447-1,1v5c0,0.553,0.447,1,1,1 S13,10.553,13,10z' fill='%23fff'/%3E%3C/svg%3E");
}

.info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M11.5 7a2 2 0 11-.001-3.999A2 2 0 0111.5 7zM15 19h-2v-9a1 1 0 00-1-1H9a1 1 0 100 2h2v8H9a1 1 0 100 2h6a1 1 0 100-2z' fill='%23fff'/%3E%3C/svg%3E");
}

.arrow {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' enable-background='new 0 0 24 24' fill='%23000'%3E%3Cpath d='M19.707,7.793c-0.391-0.391-1.023-0.391-1.414,0L12,14.086L5.707,7.793c-0.391-0.391-1.023-0.391-1.414,0 s-0.391,1.023,0,1.414l7,7C11.488,16.402,11.744,16.5,12,16.5s0.512-0.098,0.707-0.293l7-7C20.098,8.816,20.098,8.184,19.707,7.793z'/%3E%3C/svg%3E");
  transition: transform 0.15s linear;
}

.location-line {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 24 24' xml:space='preserve'%3E%3Cstyle%3E .st0{fill:%23fff} %3C/style%3E%3Cg id='XMLID_2498_'%3E%3Cpath id='XMLID_2501_' class='st0' d='M12 22a.997.997 0 01-.707-.293C11.036 21.45 5 15.353 5 10c0-4.86 2.748-8 7-8s7 3.14 7 8c0 5.375-6.037 11.452-6.294 11.708A.996.996 0 0112 22zm0-18c-3.131 0-5 2.243-5 6 0 3.554 3.473 7.827 5.001 9.534C13.528 17.829 17 13.562 17 10c0-3.757-1.869-6-5-6z'/%3E%3Ccircle id='XMLID_2500_' class='st0' cx='12' cy='9' r='2'/%3E%3C/g%3E%3C/svg%3E");
}

.trash {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' x='0' y='0' viewBox='0 0 24 24' xml:space='preserve' enable-background='new 0 0 24 24'%3E%3Cstyle%3E .st0{fill:%23fff} %3C/style%3E%3Cg id='XMLID_5684_'%3E%3Cpath id='XMLID_5721_' class='st0' d='M23,8h-2V7c0-1.654-1.346-3-3-3h-1V2.5C17,1.121,15.879,0,14.5,0h-5C8.121,0,7,1.121,7,2.5V4 H6C4.346,4,3,5.346,3,7v1H1C0.447,8,0,8.447,0,9s0.447,1,1,1h2v11c0,1.654,1.346,3,3,3h12c1.654,0,3-1.346,3-3V10h2 c0.553,0,1-0.447,1-1S23.553,8,23,8z M9.5,2h5C14.775,2,15,2.225,15,2.5V4H9V2.5C9,2.225,9.225,2,9.5,2z M5,7c0-0.552,0.448-1,1-1 h2h8h2c0.552,0,1,0.448,1,1v1H5V7z M19,21c0,0.552-0.448,1-1,1H6c-0.552,0-1-0.448-1-1V10h14V21z'/%3E%3Cpath id='XMLID_5741_' class='st0' d='M8,21c0.553,0,1-0.447,1-1v-7c0-0.553-0.447-1-1-1s-1,0.447-1,1v7C7,20.553,7.447,21,8,21z'/%3E%3Cpath id='XMLID_5742_' class='st0' d='M12,21c0.553,0,1-0.447,1-1v-7c0-0.553-0.447-1-1-1s-1,0.447-1,1v7 C11,20.553,11.447,21,12,21z'/%3E%3Cpath id='XMLID_5747_' class='st0' d='M16,21c0.553,0,1-0.447,1-1v-7c0-0.553-0.447-1-1-1s-1,0.447-1,1v7 C15,20.553,15.447,21,16,21z'/%3E%3C/g%3E%3C/svg%3E");
}

.fullscreen {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M21 8.999a1 1 0 001-1V3a1 1 0 00-1-1h-5a1 1 0 100 2h2.586L12 10.584 5.414 4.001H8a1 1 0 100-2H3a1 1 0 00-1 1V8a1 1 0 102 0V5.415l6.586 6.584L4 18.583v-2.585a1 1 0 10-2 0V21a1 1 0 001 1h5a1 1 0 100-2H5.411L12 13.412 18.589 20H16a1 1 0 100 2h5a1 1 0 001-1v-5.002a1 1 0 10-2 0v2.585l-6.586-6.585L20 5.413v2.585a1 1 0 001 1.001z' fill='%23fff'/%3E%3C/svg%3E");
}

.arrow-left-line2 {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19 11H7.63l7.034-6.252a1 1 0 00-1.328-1.495l-9 8a.996.996 0 000 1.495l9 8a.997.997 0 001.411-.083 1 1 0 00-.083-1.412L7.63 13H19a1 1 0 100-2z' fill='%23fff'/%3E%3C/svg%3E");
}

.arrow-down-line3 {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' enable-background='new 0 0 24 24' fill='%23fff'%3E%3Cpath d='M19.707,7.793c-0.391-0.391-1.023-0.391-1.414,0L12,14.086L5.707,7.793c-0.391-0.391-1.023-0.391-1.414,0 s-0.391,1.023,0,1.414l7,7C11.488,16.402,11.744,16.5,12,16.5s0.512-0.098,0.707-0.293l7-7C20.098,8.816,20.098,8.184,19.707,7.793z'/%3E%3C/svg%3E");
}

.location-line-2 {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 7.001c-1.102 0-2 .898-2 2s.898 2 2 2 2-.897 2-2-.897-2-2-2zm0 15a2 2 0 01-1.65-.868l-4.68-7.61A7.47 7.47 0 014.504 9.79c-.075-1.97.663-3.876 2.078-5.369 1.427-1.506 3.396-2.388 5.402-2.42a7.24 7.24 0 015.279 2.104C18.706 5.525 19.5 7.44 19.5 9.5a7.469 7.469 0 01-1.18 4.04l-4.644 7.55c-.4.586-1.017.911-1.676.911zm.016-18c-1.47.023-2.922.678-3.98 1.795-1.043 1.1-1.587 2.491-1.533 3.918a5.49 5.49 0 00.86 2.746l4.663 7.583 4.6-7.566A5.497 5.497 0 0017.5 9.5c0-1.52-.582-2.929-1.64-3.97a5.31 5.31 0 00-3.844-1.529z' fill='%23fff'/%3E%3C/svg%3E");
}

.user-line {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M12 14c4.904 0 5.81 4.125 5.968 6H6.018c.2-4.035 2.163-6 5.982-6m0-2c-4.506 0-8 2.406-8 8.714C4 21.424 4.575 22 5.286 22h13.428c.71 0 1.286-.576 1.286-1.286C20 16.524 17.698 12 12 12zm0-2c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z' fill='%23fff'/%3E%3C/svg%3E");
}

.credit-card {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M22 4H2a1 1 0 00-1 1v14a1 1 0 001 1h20a1 1 0 001-1V5a1 1 0 00-1-1zM3 6h18v2H3V6zm0 12v-6h18v6H3z' fill='%23fff'/%3E%3C/svg%3E");
}

.dollar {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M13 11.075V5.106c1.72.336 3 1.5 3 2.894h2c0-2.472-2.167-4.526-5-4.925V1h-2v2.075C8.167 3.474 6 5.528 6 8s2.167 4.526 5 4.925v5.968c-1.72-.335-3-1.5-3-2.893H6c0 2.472 2.167 4.526 5 4.925V23h2v-2.075c2.833-.399 5-2.453 5-4.925s-2.167-4.526-5-4.925zM8 8c0-1.394 1.28-2.558 3-2.894v5.788C9.28 10.557 8 9.393 8 8zm5 10.893v-5.787c1.72.336 3 1.5 3 2.894s-1.28 2.558-3 2.893z' fill='%23fff'/%3E%3C/svg%3E");
}

.info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M11.5 7a2 2 0 11-.001-3.999A2 2 0 0111.5 7zM15 19h-2v-9a1 1 0 00-1-1H9a1 1 0 100 2h2v8H9a1 1 0 100 2h6a1 1 0 100-2z' fill='%23fff'/%3E%3C/svg%3E");
}

.icon-container {
  display: flex;
  align-items: center;
  max-width: 40px;
  cursor: pointer;
}

.icon {
  width: 40px;
  height: 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px
}
