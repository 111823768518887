#popup{
  display: flex;
  position: fixed;
  bottom: -125px;
  right:50px;
  width: auto;
  height: 75px;
  background: black;
  color: white;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: opacity 0.3s;
  z-index: -1;
  opacity: 0
}
#poptext{
  margin:0 40px;
}
#popclose{
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
