.invalid-feedback{
  display: block;
  font-size: 90%;
}

.invalid-input{
  user-select: none;
  border: 1px solid red !important;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25) !important;
    border: 1px solid red !important;
  }
}

.box-form{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.input {
  height: 40px;
  border: 1px solid gainsboro;
  padding-left: 1em;
  box-sizing: border-box;
  color: $input-text-color;
  background-color: white;
}

.number-localized{
  color: $input-text-color;
  text-align: start;
  padding-left: 1em;
}

.input-transparent {
  height: 40px;
  border: 1px solid transparent;
  padding-left: 1em;
  box-sizing: border-box;
  color: $input-text-color;
  background-color: transparent;
}

.label {
  width: 100%;
  user-select: none;
}

input[type=checkbox].css-checkbox {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;

}
// -------------------------------------------------------- OLD CHECKBOX
// input[type=checkbox].css-checkbox + label.css-label {
//   padding-left: 30px;
//   height: 25px;
//   display: inline-block;
//   line-height: 25px;
//   background-repeat: no-repeat;
//   background-position: 0 0;
//   vertical-align: middle;
//   cursor: pointer;
// }

// input[type=checkbox].css-checkbox:checked + label.css-label {
//   background-position: 0 -25px;
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
// }


// label.css-label {
//   background-image:url(http://csscheckbox.com/checkboxes/u/csscheckbox_d55e0f0e93668de88d994b653479549e.png);
//   -webkit-touch-callout: none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   -moz-user-select: none;
//   -ms-user-select: none;
//   user-select: none;
//   -webkit-tap-highlight-color:transparent;
// }

// -------------------------------------------------------- END OLD CHECKBOX

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button,
input[type=time]::-webkit-inner-spin-button,
input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius:5px;
}
/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-color: $mainColor;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

