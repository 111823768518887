/*** Custom loading bar ***/
#loading-bar-spinner {
  position: fixed !important; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0 !important;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.3); /* Black background with opacity */
  z-index: 2999999 !important; /* Specify a stack order in case you're using a different order for other elements */
}

#loading-bar-spinner .spinner-icon{
  top:40%;
  position: absolute;
  left:48%;
}

#loading-bar .bar{
  top: 50%;
}
