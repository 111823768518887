/* POPOVER */

.tk3dpopover {
  border-radius: 5px;
  padding: 4px;
  background-color: #161c27;
  max-width: 345px;
}

#arrow,
#arrow::before {
  position: absolute;
  width: 12px;
  height: 12px;
  z-index: -1;
}

#arrow::before {
  content: '';
  transform: rotate(45deg);
  background: #161c27;
}

#tooltip {
  /* ... */
  display: none;
}

#tooltip[data-show] {
  display: block;
}

#tooltip[data-popper-placement^='top'] > #arrow {
  bottom: -6px;
}

#tooltip[data-popper-placement^='bottom'] > #arrow {
  top: -6px;
}

#tooltip[data-popper-placement^='left'] > #arrow {
  right: -6px;
}

#tooltip[data-popper-placement^='right'] > #arrow {
  left: -6px;
}

.popover-body {
  padding: 10px 15px 0px 15px;
  margin-bottom: 6px;
}

.ticket-description {
  color: #e2e2e2;
  font-style: italic;
}

.ticket-lab {
  color: #98a7b9;
  font-size: 14px;
  font-family: "Montserrat", Arial, sans-serif;
  margin-right: 5px;
}

.ticket-val {
  color: #fff;
  font-size: 14px;
  font-family: 'Montserrat', Arial, sans-serif;
}

.tk3dpopover .popover-content {
  padding: 0;
}

.tk3dpopover .popover-content .ticket-selected {
  margin: 0;
}

.ticket-selected-view {
  width: 250px;
  height: 50px;
  padding: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}

.ticket-selected-view-img {
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
  width: 335px;
  height: 335px;
  position: absolute;
  top: -85px;
  left: 0;
}

.ticket-selected-seat {
  margin: 0px 12px 8px;
  text-align: center;
  justify-content: space-evenly;
}

.ticket-selected {
  // margin: 10px 0 0;
  text-align: center;
}

.popover-content .ticket-selected li:first-child,
.popover-content .ticket-selected-seat li:first-child {
  border: 0;
}

.popover-content .ticket-selected li,
.popover-content .ticket-selected-seat li {
  display: inline-block;
  vertical-align: top;
  border-left: 1px solid #ccc;
  padding: 0px 7px;
}

/* END POPOVER */


#main-interface {
  background: #000;
}

.nav-tabs .nav-link {
  border-top-right-radius: 0rem !important;
  border-top-left-radius: 0rem !important;
}

a.nav-link:hover {
  color: black;
}

.wrap-interface {
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  background-size: cover;
}

.side-interface {
  margin: 20px 0 20px 22px;
  border-radius: 5px;
  overflow: hidden;
  width: 95%;
}

#map-interface-card {
  margin: 20px 22px 20px 20px;
  border-radius: 5px;
  overflow: hidden;
  // background-color: white;
  -webkit-box-shadow: 0px 0px 10px 0px #d2d2d2;
  -moz-box-shadow: 0px 0px 10px 0px #d2d2d2;
  box-shadow: 0px 0px 10px 0px #d2d2d2;
  position: relative;
}

/* MINIMAP */

#area-map-mini,
#seatmap-mini,
#blockmap-mini {
  -webkit-filter: grayscale(80%);
  filter: grayscale(80%);
  transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -webkit-transition: .25s ease-in-out;
  cursor: pointer;
}

#area-map-mini:hover,
#seatmap-mini:hover,
#blockmap-mini:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

#minimap {
  position: absolute;
  top: 60px;
  right: 15px;
  height: 113px;
  width: 161px;
  z-index: -1;
  opacity: 0.95;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.85);
  box-shadow: 0px 5px 4.8px 0.3px rgba(9, 9, 9, 0.15);
}

.search-item{
    cursor: pointer;
    padding: 9px 15px;
}

.search-item.s-active{
    background-color: #ddd
}

/* Neighbor*/

#seatmap .neighbor-btn {
  background-color: black;
  color: white;
  border: 5px;
  font-size: 20px !important;
}




/********* TK3D STYLES *********/

// .block.unavailable {
//   fill: #c1c1c1 !important;
// }

// .block.available {
//   fill: #1D8EF9 !important;
//   stroke-width: 1.5px;
//   opacity: 0.7 !important;
//   -webkit-transition: 0.3s;
//   /* Safari */
//   transition: 0.3s;
//   transition-timing-function: ease-in;
// }

// .seat.available,
// .seat.available:hover {
//   // fill: green !important;
//   // stroke-width: 1.5px;
//   // opacity: 0.8 !important;
//   // -webkit-transition: 0.3s; /* Safari */
//   // transition: 0.3s;
//   // transition-timing-function: ease-in;
//   fill: #1D8EF9 !important;
//   stroke-width: 1.5px;
//   opacity: 0.7 !important;
//   -webkit-transition: 0.3s;
//   /* Safari */
//   transition: 0.3s;
//   transition-timing-function: ease-in;
// }

.block.unavailable {
  fill: #c7c7c7 !important;
}

.seat.unavailable {
  opacity: 0 !important;
}

.block.unavailable {
  -webkit-transition: 0.25s;
  /* Safari */
  transition: 0.25s;
}

#main-interface .seat.unavailable,
#main-interface .seat.unavailable:hover {
  cursor: pointer;
}

#main-interface .seat.unavailable.customerView,
#main-interface .seat.unavailable.customerView:hover {
  cursor: default !important;
}


#main-interface .block.available,
#area-map-mini .block.available,
#seatmap-mini .seat.available,
#seatmap-mini {
  fill: #1D8EF9 !important;
  stroke-width: 1.5px;
  opacity: 0.7 !important;
  -webkit-transition: 0.3s;
  /* Safari */
  transition: 0.3s;
  transition-timing-function: ease-in;
  fill-opacity: 0.8 !important;

  &:hover {
    fill: #eaeaea !important;
  }
}



#main-interface .seat.available {
  fill: #1D8EF9 !important;
  stroke-width: 3px;
  opacity: 1 !important;
  fill-opacity: 0.8 !important;
  -webkit-transition: 0.25s;
  /* Safari */
  transition: 0.25s;

  &:hover {
    fill: #eaeaea !important;
  }
}

#main-interface .block.available:hover,
#main-interface .block.selected,
#area-map-mini .block.selected,
#seatmap-mini .seat.selected {
  fill: #eaeaea !important;
  opacity: 1 !important;
  stroke-width: 3px;
  stroke: #fff !important;
  fill-opacity: 0.8 !important;
  -webkit-transition: 0.25s;
  /* Safari */
  transition: 0.25s;
}

#main-interface .seat.locked {
  stroke: red !important;
  stroke-width: 3px;
  opacity: 0.8 !important;
  -webkit-transition: 0.3s;
  /* Safari */
  transition: 0.3s;
  transition-timing-function: ease-in;
  fill-opacity: 0.8 !important;

  &:hover {
    fill: #eaeaea !important;
  }
}

.seat.on_hold {
  fill: yellow !important;
  stroke-width: 3px;
  opacity: 1 !important;
  -webkit-transition: 0.3s;
  /* Safari */
  transition: 0.3s;
  transition-timing-function: ease-in;

  &:hover {
    fill: #eaeaea !important;
  }
}

.seat.reserved {
  fill: orangered !important;
  stroke-width: 3px;
  opacity: 1 !important;
  fill-opacity: 0.8 !important;
  -webkit-transition: 0.3s;
  /* Safari */
  transition: 0.3s;
  transition-timing-function: ease-in;

  &:hover {
    fill: #eaeaea !important;
  }
}

#main-interface .seat.owned {
  fill: orange !important;
  opacity: 1 !important;
  stroke-width: 3px;
  //stroke: #fff !important;
  fill-opacity: 0.8 !important;
  -webkit-transition: 0.25s;
  /* Safari */
  transition: 0.25s;

  &:hover {
    fill: #eaeaea !important;
  }
}

#main-interface .seat.no-pricetype{
  fill: darkgray !important;
  opacity: 1 !important;
  stroke-width: 3px;
  //stroke: #fff !important;
  fill-opacity: 0.8 !important;
  -webkit-transition: 0.25s;
  /* Safari */
  transition: 0.25s;
  &:hover {
    fill: #eaeaea !important;
  }
}

#main-interface .seat.selected {
  // fill: rgb(255, 251, 0) !important;
  fill: #eaeaea !important;
  stroke-width: 2px;
  stroke: #fff !important;
  opacity: 1 !important;
  fill-opacity: 0.8 !important;
  -webkit-transition: 0.3s;
  /* Safari */
  transition: 0.3s;
  transition-timing-function: ease-in;
}


/* LOADER */

#blockmap-loader,
#seatmap-loader {
  background-color: rgba(17, 17, 17, 0.6) !important;
}

#blockmap-loader:after,
#seatmap-loader:after {
  content: "\f1ce";
  font-family: FontAwesome;
  left: 47%;
  top: 43%;
  position: absolute;
  font-size: 40pt;
  margin: 0 !important;
  padding: 0 !important;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
  text-align: center;
  color: #252525;
}

#blockmap-loader:before {
  content: "Building the venue...";
  top: 56%;
  width: 100%;
  position: absolute;
  font-size: 12pt;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  font-family: 'Poppins-SemiBold', Arial, sans-serif;
  color: #252525;
}

#seatmap-loader:before {
  content: "Preparing your seats...";
  top: 56%;
  width: 100%;
  position: absolute;
  font-size: 12pt;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  font-family: 'Poppins-SemiBold', Arial, sans-serif;
  color: #252525;
}

// #main-interface .seat.available:hover,
// .seat.selected:hover,
// .seat.locked:hover,
// .seat.on_hold:hover,
// .seat.reserved:hover,
// .seat.unavailable.owned:hover  {
//   fill: #eaeaea !important;
//   stroke-width: 1.5px;
//   opacity: 0.7 !important;
//   -webkit-transition: 0.3s;
//   /* Safari */
//   transition: 0.3s;
//   transition-timing-function: ease-in;
//   fill-opacity: 0.8 !important;
// }
