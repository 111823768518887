// TABLES

table{
  border-collapse: unset
}

table th, table tr{
  border-top: transparent !important
}

table th {
  vertical-align: middle !important;
  color: #252525;
}

table tr{
  color: $table-text-color;
}


thead {
  font-weight: 400;
  font-size: 1.2em;
}

th {
  font-weight: 400;
}

.customTable {
  display: table-header-group;
  height: auto;
  margin: auto;
  background: white;
  text-align: left;
}

.price-mng-table {
  display: table-header-group;
  height: auto;
  width: 300px;
  margin: auto;
  background: white;
  text-align: left;
}

.t-row {
  height: 45px;
  transition: background 0.2s;
  cursor: default;
  padding: 0 1em;
  box-sizing: border-box;
  background: white;
  text-align: center;
}

.t-data {
  width: 200px;
  margin: 0;
  border-style: none;
}

.t-col10 {
  width: 10%;
}

.t-col20 {
  width: 20%;
}

.special-td{
  text-align: center;
  vertical-align: baseline;
}

.hov:hover {
  background: rgba(220, 220, 220, 0.3);
}

.pointer {
  cursor: pointer;
}

.line {
  box-sizing: border-box;
  border-bottom: 1px solid lightgrey;
}

.newSeatTable {
  max-height: 158px;
  overflow: auto;
}

.total-right {
  text-align: right;
  padding: 0 40px;
}
