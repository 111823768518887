
//Font definition

$font-bold: 'Poppins-Bold';
$font-medium: 'poppins';
$font-light: 'Poppins-Light';


// Colors
$header-color: var(--header-color, black);
$light-gray: #f3f5f8; //bg
$mainColor: var(--main-color, black);

// $uxColor: #388be2;
$uxColor: var(--main-color, black);
$hover-side-nav-background: #252525;
$hover-side-nav-text: white;
$active-side-nav-background: #252525;
$active-side-nav-text: white;
$darkColor: #000033;
$button-hover: white;
$button-hover2: #f3f5f8;
$backgroundColor: #f3f5f8;
$text-color: #232323;
$input-text-color:  #232323;
$table-text-color: #616161;

$popover-color: #d81c2b;
// Button colors

$button-active: #20c768;
$button-active-hover: #2bc565;
$button-inactive: #fd7a7a;
$button-inactive-hover: #ff6e6e;

// Sizes

$sidenav-width: 100px;
